import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import IlLogo from '../components/IlLogo/IlLogo';

import MainLayout from '../layaouts/MainLayout/MainLayout';

function LogoPage({ pageContext }) {

  const {
    data,
  } = pageContext;

  return (
    <MainLayout>
      <IlLogo data={data} />
    </MainLayout>
  );
}

export default injectIntl(LogoPage);
