import React, { useMemo, useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { map } from 'lodash'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import './style.css'
import ImageBlock from '../ImageBlock/ImageBlock'
import Carusel from '../Forms/Carusel/Carusel'
import MetaTags from '../Header/MetaTags'

export default function IlLogo({ data }) {
  const intl = useIntl()
  const breakpoints = useBreakpoint()
  const {
    foundationHero,
    logoCards,
    logoDescriptionBlocks,
    paletteBlock,
  } = data[intl.locale].acf
  const { cards } = logoCards
  const { yoast_head_json } = data[intl.locale]

  const palette = useMemo(
    () => [
      {
        color: '#D75F2B',
        title: 'ORANGE TENNé',
        subTitle: paletteBlock.orangeLabel,
      },
      {
        color: '#5C5C5B',
        title: 'dark gray',
        subTitle: paletteBlock.grayLabel,
      },
      {
        color: '#A6322E',
        title: 'LIGHT BORDEAUX',
        subTitle: paletteBlock.bordeauxLabel,
      },
      {
        color: '#003F6E',
        title: 'CLASSIC BLUE',
        subTitle: paletteBlock.blueLabel,
      },
    ],
    [paletteBlock]
  )

  const cardsToRender = useMemo(
    () =>
      map(cards, ({ cardIcon, cardTitle }) => (
        <div className="promImgContainer--ilLogo_web" key={cardTitle}>
          <img
            alt="pomotoriIMG"
            src={cardIcon}
            className="overImg--ilLogo_web"
          />
          <div className="label--ilLogo_web">{cardTitle}</div>
        </div>
      )),
    [cards]
  )

  const descriptionsToRender = useMemo(
    () =>
      map(
        logoDescriptionBlocks.blockLogo,
        ({ blockTitle, blockContent, image }) => {
          if (!image) return null
          return (
            <div key={blockTitle} className="block--ilLogo">
              <h2 className="blockTitle--ilLogo">
                {blockTitle.length > 0 && blockTitle}
              </h2>
              <div className="blockContent--ilLogo">
                {blockContent.length > 0 && blockContent}
              </div>
              <img
                alt="imgLogo"
                src={image}
                style={{
                  objectFit: 'cover',
                  width: breakpoints.md ? 316 : 793,
                  height: breakpoints.md ? 167 : 420,
                }}
              />
            </div>
          )
        }
      ),
    [logoDescriptionBlocks, breakpoints]
  )

  useEffect(() => {
    const mylist = document.getElementById('logo')
    const divs = mylist.children
    const listitems = []
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i))
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id')
      const compB = b.getAttribute('id')
      return compA < compB ? -1 : compA > compB ? 1 : 0
    })
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i])
    }
  }, [])

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="logo" style={{ backgroundColor: '#f8f9fa' }}>
        {foundationHero.imageBackgroundFoundationHero && foundationHero.title && (
          <div
            id={foundationHero.positionFoundationHero}
            style={{
              position: 'relative',
              height: !breakpoints.sm ? 660 : '60vh',
              marginBottom: !breakpoints.sm ? 80 : 100,
            }}
          >
            <div style={{ height: !breakpoints.sm ? '100%' : '60%' }}>
              <ImageBlock
                isTitleH1
                type="3"
                title={foundationHero.title}
                img={foundationHero.imageBackgroundFoundationHero}
                style={{
                  marginBottom: breakpoints.md ? 80 : 140,
                  position: 'absolute',
                }}
              />
            </div>

            {cards.length > 0 && (
              <div
                className="over--ilLogo"
                style={{ marginBottom: breakpoints.xs && 0 }}
              >
                {!breakpoints.md && cardsToRender}
                {breakpoints.md && (
                  <div style={{ width: '100%' }}>
                    <Carusel
                      component="IlLogoTop"
                      cards={cards}
                      width={202}
                      height={145}
                      showArrows={false}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {logoDescriptionBlocks.blockLogo.length > 0 && (
          <div id={logoDescriptionBlocks.positionLogoDescription}>
            {descriptionsToRender}
          </div>
        )}

        {paletteBlock && (
          <div id={paletteBlock.palettePosition}>
            {breakpoints.md ? (
              <>
                <h3 className="paletteTitle" style={{ marginLeft: 25 }}>
                  La Palette
                </h3>
                <Carusel
                  component="IlLogo"
                  cards={palette}
                  width={180}
                  height={244}
                  bgColor="white"
                />

                <br />
              </>
            ) : palette[0] ? (
              <div className="paletteBlock">
                <h3 className="paletteTitle">
                  {intl.formatMessage({ id: 'il_logo_palette_block_title' })}
                </h3>
                <div className="paletteContainer">
                  <div className="palette">
                    <div
                      style={{
                        width: '100%',
                        height: '180px',
                        backgroundColor: palette[0].color,
                      }}
                    />
                    <div className="paletteContentTitle">
                      {palette[0].title}
                    </div>
                    <div className="paletteContentSubTitle">
                      {palette[0].subTitle}
                    </div>
                  </div>
                  <div className="palette">
                    <div
                      style={{
                        width: '100%',
                        height: '180px',
                        backgroundColor: palette[1].color,
                      }}
                    />
                    <div className="paletteContentTitle">
                      {palette[1].title}
                    </div>
                    <div className="paletteContentSubTitle">
                      {palette[1].subTitle}
                    </div>
                  </div>
                  <div className="palette">
                    <div
                      style={{
                        width: '100%',
                        height: '180px',
                        backgroundColor: palette[2].color,
                      }}
                    />
                    <div className="paletteContentTitle">
                      {palette[2].title}
                    </div>
                    <div className="paletteContentSubTitle">
                      {palette[2].subTitle}
                    </div>
                  </div>
                  <div className="palette">
                    <div
                      style={{
                        width: '100%',
                        height: '180px',
                        backgroundColor: palette[3].color,
                      }}
                    />
                    <div className="paletteContentTitle">
                      {palette[3].title}
                    </div>
                    <div className="paletteContentSubTitle">
                      {palette[3].subTitle}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  )
}
